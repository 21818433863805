import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { motion } from "framer-motion"
import Layout from "../layouts/layout"
import SEO from "../components/seo"

const Container = styled(motion.div)`
  width: 95%;
  box-shadow: ${props => props.theme.imageBoxShadow};
  outline: none;

  @media (max-width: 700px) {
    width: 100%;
  }
`

const Image = ({ image }) => {
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      tabIndex="0"
    >
      <Img fluid={image.src.cloudinary.fluid} alt={image.alt} />
    </Container>
  )
}

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      galleryJson(category: { eq: "hero" }) {
        images(name: "monument-valley") {
          src {
            cloudinary: childCloudinaryAsset {
              fluid(
                maxWidth: 1300
                transformations: ["q_auto:best", "f_auto"]
              ) {
                ...CloudinaryAssetFluid
              }
              fixed(transformations: ["t_og_image", "f_jpg"]) {
                ...CloudinaryAssetFixed
              }
            }
          }
          alt
        }
      }
    }
  `)

  const [image] = data.galleryJson.images

  return (
    <Layout>
      <SEO image={image.src.cloudinary.fixed.src} />
      <Image image={image} />
    </Layout>
  )
}

export default IndexPage
